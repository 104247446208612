/* UI react already includes tailwind base */
/* @tailwind base; */
/* 
*:focus {
  @apply ss-focus-ring;
} */

/* input without type attribute, will match type=text implicitly */
input:not([type]) {
  appearance: none;
}

/* for date input, to remove the default icon */
input[type='datetime-local']::-webkit-inner-spin-button,
input[type='datetime-local']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  /* display: none; */
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;

  /* -webkit-appearance: none; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px theme('colors.white') inset !important;
  background-color: theme('colors.white') !important;
  background-clip: content-box !important;
}

/* added so that the volume banks modal can overlap the dashboard header on mobile view */
div[data-rsbs-overlay='true'] {
  z-index: 50;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@tailwind components;
@tailwind utilities;
@tailwind screens;

@import url('./styles/avenir.css');
@import url('./styles/made-outer-sans.css');
@import url('./styles/overrides.css');
